
export const socials = [
  {
    icon: "mdi:instagram",
    name: "Instagram",
    link: "https://www.instagram.com/washington_firearm_training/"
  },
  {
    icon: "mdi:twitter",
    name: "Twitter",
    link: "https://mobile.twitter.com/wdftraining/"
  },
  {
    icon: "mdi:facebook",
    name: "Facebook",
    link: "https://www.facebook.com/profile.php?id=100093442422317"

  },
]