export const products = [
  {
    title: "WEAR & CARRY",
    price: "$250.00",
  },
  {
    title: "HANDGUN QUALIFICATION LICENSE (HQL)",
    price: "$150.00",
  },
  {
    title: "HANDGUN RENEWAL",
    price: "$150.00",
  },
  {
    title: "FIRST TIME SHOOTER",
    price: "$100.00",
  },
  {
    title: "SECURITY GUARD CLEARANCE",
    price: "$200.00",
  },
  {
    title: "SELF DEFENSE TRAINING",
    price: "$100.00",
  },
]