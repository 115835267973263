export const navs = [
  {
    text: "Home",
    path: "#home"
  },
  {
    text: "About",
    path: "#about"
  },
  {
    text: "Classes",
    path: "#classes"
  },
  {
    text: "Location",
    path: "#location"
  },
  {
    text: "Contact",
    path: "#contact"
  },
]